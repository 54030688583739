<template>
  <div>
    <validation-observer ref="userRules">
      <b-form @submit.prevent>
        <b-row col>
          <b-col md="6">
            <b-form-group label="First Name" label-for="mc-first_name">
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required|alpha|min:3|max:50"
              >
                <b-form-input
                  v-model="userDetailsForm.first_name"
                  id="mc-first_name"
                  class="first_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="First Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Last Name" label-for="mc-last_name">
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required|alpha|min:3|max:50"
              >
                <b-form-input
                  v-model="userDetailsForm.last_name"
                  id="mc-last_name"
                  class="last_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Last Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

           <b-col md="6">
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email|max:256"
              >
                <b-form-input
                  v-model="userDetailsForm.email"
                  id="email"
                  class="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3" v-if="!whitelabelEdit">
            <b-form-group
              label="Password"
              label-for="mc-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">

                  <b-form-input
                    v-model="userDetailsForm.password"
                    class="password"
                    :type="passwordFieldTypeNew"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Your Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer togglePasswordNew"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3" v-if="!whitelabelEdit">
            <b-form-group
              label="Confirm Password"
              label-for="mc-confirm-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password Confirm"
                rules="required|confirmed:Password"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">

                  <b-form-input
                    v-model="userDetailsForm.confirm_password"
                    class="confirm_password"
                    :state="errors.length > 0 ? false:null"
                    :type="passwordFieldTypeConfirm"
                    placeholder="Confirm Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconConfirm"
                      class="cursor-pointer togglePasswordConfirm"
                      @click="togglePasswordConfirm"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                label="Country"
                label-for="h-country"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <v-select
                    v-model="userDetailsForm.country_id"
                    id="h-country"
                    :options="countries"
                    class="country"
                    placeholder="Select Country"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="State" label-for="mc-State">
              <validation-provider
                #default="{ errors }"
                name="State"
                rules="required|max:100|min:3"
              >
                <b-form-input
                  v-model="userDetailsForm.state"
                  id="mc-State"
                  class="State"
                  :state="errors.length > 0 ? false : null"
                  placeholder="State"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="City" label-for="mc-City">
              <validation-provider
                #default="{ errors }"
                name="City"
                rules="required|max:100|min:3"
              >
                <b-form-input
                  v-model="userDetailsForm.city_name"
                  id="mc-City"
                  class="City"
                  :state="errors.length > 0 ? false : null"
                  placeholder="City"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Address" label-for="mc-Address">
              <validation-provider
                #default="{ errors }"
                name="Address"
                rules="max:250||min:3"
              >
                <b-form-input
                  v-model="userDetailsForm.address"
                  id="mc-Address"
                  class="Address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Address"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- submit and reset -->
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="float-right"
              :disabled="isSubmitted"
               @click="$emit('on-submit', userDetailsForm, 'user')"
            >
              <feather-icon
                icon="SaveIcon"
                size="14"
              />
              Continue
              <feather-icon
                icon="ChevronRightIcon"
                size="14"
              />
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button
              variant="secondary"
              class="mr-1 float-right"
              :to="{name: 'whitelabel-list' }"
            >
            <feather-icon
                icon="ChevronLeftIcon"
                size="14"
              />
            Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
	BInputGroup,
  BForm,
  BAlert,
  BButton
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, email, confirmed, alpha, password, positive } from "@core/utils/validations/validations";
import {
  resConditionCheck
} from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import CountryService from '@/services/country/country.service'
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BCardCode,
    BAlert,
    BButton,
    BInputGroupAppend,
	  BInputGroup,
  },

  directives: {
    Ripple
  },

  props: {
    userDetailsForm: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      countries: [],
      countriesList: [],
      loading: false,
      isSubmitted: false,
      required,
      email,
      confirmed,
      alpha,
      password,
      positive,
      passwordFieldTypeConfirm: 'password',
      passwordFieldTypeNew: 'password',
      whitelabelEdit: this.$route.name !== 'whitelabel-add',
    };
  },

  watch: {
    
  },

  async mounted() {
    this.getCountryList();
  },

  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordFieldTypeConfirm === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },

  methods: {
    getCountryList() {
      CountryService.getCountryList()
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.countriesList = res.data.data;
            this.countries = res.data.data.map(e => ({
              label: e.country,
              value: e.id
            }))
          }
        }).catch(error => {
          errorResponseHandler(error)
        })
    },
    async validationForm() {
      let valid = null;
      await this.$refs.userRules.validate().then(success => {
        if (success) {
          valid = true;
        } else {
          valid = false;
        }
      });
      return valid;
    },
    togglePasswordConfirm() {
      this.passwordFieldTypeConfirm = this.passwordFieldTypeConfirm === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
  }
};
</script>

