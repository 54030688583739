<template>
  <div>
    <confirm-disable
      ref="newsLetterModal"
      :msg="disableMsg"
      @on-submit="showNewsletter"
      @on-hide="resetShowNewsletter"
    />
    <validation-observer ref="contentRules">
      <b-form @submit.prevent>
        <div class="mb-2 border border-light rounded">
          <h3 class="mb-1 headingStyle">Social Links</h3>
        <b-row col class="px-1">
          <b-col
            v-for="(item, index) in contentDetails.social_links"
            :key="index"
            md="6">
            <b-form-group :label="item.name" :label-for="item.name" label-cols-lg="4">
              <div class="d-flex">
                <b-form-checkbox
                  v-model="item.is_active"
                  class="custom-control-primary checkStyle"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon :icon="icons[item.name]" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="DiscIcon" />
                  </span>
                </b-form-checkbox>
                <validation-provider
                  v-if="item.is_active"
                  #default="{ errors }"
                  :name="item.name"
                  rules="required|url"
                >
                  <b-form-input
                    v-model="item.url"
                    :class="item.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="item.name"
                    :disabled="!item.is_active"
                  />
                  <small v-if="item.is_active" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        </div>

        <div class="mb-2 border-light rounded">
          <b-row col class="px-1">
            <b-col md="8">
              <h3 class="px-0 mb-1 headingStyle">
              Header Section 
              <br>
              <small style="color: red; font-style: italic;">(<strong>Note:</strong><span>Max 2 pages can be <strong>ACTIVE</strong> at a time.</span>)</small>
              </h3>
            </b-col>
            <b-col>
              <b-button
                v-b-modal.modal-add-menu
                v-ripple.200="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="p-1 my-1 float-right"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  size="18"
                  style="margin: -2px 0px;"
                /> <strong>New Menu</strong>
              </b-button>
            </b-col>
          </b-row>
        <b-row col class="px-1">
          <b-col
            v-for="(item, index) in contentDetails.header_links"
            :key="index"
            md="12">
            <validation-observer :ref="`menuUpdateUrl_${item.id}`">
              <b-form @submit.prevent>
                <b-form-group :label="item.name" :label-for="item.name" label-cols-lg="2">
                  <div class="d-flex">
                    <b-form-checkbox
                      v-model="item.isActive"
                      class="custom-control-primary checkStyle"
                      switch
                      @change="activeDeactiveMenu(item.id, 1)"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="Link2Icon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="DiscIcon" />
                      </span>
                    </b-form-checkbox>
                    <div class="inline-box ml-2" v-if="item.isActive">
                      <b-form-radio
                        :name="item.name"
                        class="my-0 mt-0.5"
                        value="url"
                        :checked="item.isUrl ? 'url' : 'slug'"
                        @change="updateContentType('url', index, 'header')"
                      >
                        URL
                      </b-form-radio>
                      <validation-provider
                        #default="{ errors }"
                        :name="item.name"
                        :rules="`${item.isUrl ? 'required|url' : ''}`"
                      >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model="item.url"
                          :class="item.name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter external URL"
                          :disabled="!item.isUrl"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            v-if="item.isUrl"
                            class="btn p-0 text-primary"
                            icon="SaveIcon"
                            size="18"
                            @click="updateMenuUrl(item, `menuUpdateUrl_${item.id}`)"
                          />
                          <feather-icon
                            v-if="!item.isUrl"
                            class="btn p-0 disabled"
                            icon="SaveIcon"
                            size="18"
                          />
                        </b-input-group-append>
                      </b-input-group>
                        <small v-if="item.isUrl" class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <!-- <button
                        class="btn-primary border rounded mt-0.5"
                        v-if="item.isUrl"
                        @click="updateMenuUrl(item, `menuUpdateUrl_${item.id}`)"
                      >
                        <feather-icon
                          icon="SaveIcon"
                          size="18"
                        />
                      </button> -->
                      <b-form-radio
                        v-if="item.isDefault"
                        :name="item.name"
                        value="slug"
                        :checked="item.isSlug ? 'slug' : 'url'"
                        class="my-0 mt-0.5"
                        @change="updateContentType('slug', index, 'header')"
                        @click="openUpdateContentModal(item.id)"
                      >
                       <span
                        v-b-modal.modal-add-edit-content
                        :class="`my-0 mt-0.5 ${(item.contentLanguages.length < 1 && !item.isUrl) ? 'text-danger font-weight-bold' : ''}`"
                        @click="openUpdateContentModal(item.id)"
                      >
                        <feather-icon icon="EditIcon" /> Add Content
                      </span> 
                      </b-form-radio>
                      <!-- <button
                        v-b-modal.modal-add-edit-content
                        class="border my-0 mt-0.5"
                        v-if="item.isSlug && item.isDefault"
                        @click="openUpdateContentModal(item.id)"
                      >
                        <feather-icon icon="EditIcon" />
                      </button> -->
                    </div>
                  </div>
                </b-form-group>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
        </div>

        <div class="mb-2 border-light rounded">
          <h3 class="mb-1 headingStyle">
            Footer Section
            <br>
            <small style="color: red; font-style: italic;">(<strong>Note:</strong><span>Max 5 pages can be <strong>ACTIVE</strong> per category.</span>)</small>
          </h3>
          <b-col md-12>
            <b-form-group label="Show Newsletter" label-for="mc-showNewsletter" label-cols-lg="4">
              <b-form-checkbox
                v-model="contentDetails.show_newsletter"
                class="custom-control-primary checkStyle"
                switch
                @change="openNewsletterModal( whitelabelId, contentDetails.show_newsletter )"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        <b-row v-for="(menu, idx) in footerMenus" :key="idx" col class="px-1">
          <h4 class="ml-1 pt-1"><u>{{ footer_cat[idx].label }}</u></h4>
          <hr>
          <b-col
            v-for="(item, index) in menu"
            :key="index"
            md="12">
            <validation-observer :ref="`menuUpdateUrl_${item.id}`">
              <b-form @submit.prevent>
                <b-form-group :label="item.name" :label-for="item.name" label-cols-lg="2">
                  <div class="d-flex">
                    <b-form-checkbox
                      v-model="item.isActive"
                      class="custom-control-primary checkStyle"
                      switch
                      @change="activeDeactiveMenu(item.id, 2, idx)"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="Link2Icon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="DiscIcon" />
                      </span>
                    </b-form-checkbox>
                    <div class="inline-box ml-2" v-if="item.isActive">
                      <b-form-radio
                        :name="item.name"
                        class="my-0 mt-0.5"
                        :checked="item.isUrl ? 'url' : 'slug'"
                        value="url"
                        @change="updateContentType('url', index, 'footer', idx)"
                      >
                        URL
                      </b-form-radio>
                      <validation-provider
                        #default="{ errors }"
                        :name="item.name"
                        :rules="`${item.isUrl ? 'required|url' : ''}`"
                      >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model="item.url"
                          :class="item.name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter external URL"
                          :disabled="!item.isUrl"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            v-if="item.isUrl"
                            class="btn p-0 text-primary"
                            icon="SaveIcon"
                            size="18"
                            @click="updateMenuUrl(item, `menuUpdateUrl_${item.id}`)"
                          />
                          <feather-icon
                            v-if="!item.isUrl"
                            class="btn p-0 disabled"
                            icon="SaveIcon"
                            size="18"
                          />
                        </b-input-group-append>
                      </b-input-group>
                        <small v-if="item.isUrl" class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <!-- <button
                        class="btn-primary border rounded mt-0.5"
                        v-if="item.isUrl"
                        @click="updateMenuUrl(item, `menuUpdateUrl_${item.id}`)"
                      >
                        <feather-icon
                          icon="SaveIcon"
                          size="18"
                        />
                      </button> -->
                      <b-form-radio
                        v-if="item.isDefault"
                        :name="item.name"
                        value="slug"
                        :checked="item.isSlug ? 'slug' : 'url'"
                        class="my-0 mt-0.5"
                        @change="updateContentType('slug', index, 'footer', idx)"
                        @click="openUpdateContentModal(item.id)"
                      >
                       <span
                        v-b-modal.modal-add-edit-content
                        :class="`my-0 mt-0.5 ${(item.contentLanguages.length < 1 && !item.isUrl) ? 'text-danger font-weight-bold' : ''}`"
                        @click="openUpdateContentModal(item.id)"
                      >
                        <feather-icon icon="EditIcon" /> Add Content
                      </span> 
                      </b-form-radio>
                      <!-- <button
                        v-b-modal.modal-add-edit-content
                        class="border my-0 mt-0.5"
                        v-if="item.isSlug && item.isDefault"
                        @click="openUpdateContentModal(item.id)"
                      >
                        <feather-icon icon="EditIcon" />
                      </button> -->
                    </div>
                  </div>
                </b-form-group>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
        </div>
        
        <!-- Add / Update Menu Content Modal ***START*** -->
        <b-modal
          id="modal-add-edit-content"
          ref="contentAddModal"
          title="Add Content"
          cancel-title="Close"
          ok-title="Save"
          centered
          size="xl"
          cancel-variant="outline-secondary"
          no-close-on-backdrop
          no-close-on-esc
          @ok="updateMenu"
        > 
          <b-overlay
            variant="white"
            :show="isLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
          >

          <validation-observer ref="addUpdateMenuFormRules">
            <b-form @submit.prevent>
              <b-row>
                <b-col md="12">
                    <strong><b-form-group :label="`Menu Name: ${menuName}`" /></strong>
                  </b-col>
              </b-row>
              <b-row col v-for="(lang, index) in updateMenuContent.contents" :key="index">
                <!-- <span v-if="lang.isActive && !lang.isDeleted"> -->
                  <!-- <b-col md="12"> -->
                    <b-col md="12">
                      <b-form-group :label="`${lang.languageName} Title`" :label-for="`${lang.languageName}-title`">
                        <validation-provider
                          #default="{ errors }"
                          :name="`${lang.languageName} Title`"
                          rules="required|min:3|max:200"
                        >
                          <b-form-input
                            v-model="updateMenuContent.contents[index].name"
                            :class="`${lang.languageName}-title`"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="`${lang.languageName} Title`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group :label="`${lang.languageName} Description`" :label-for="`mc-${lang.languageName}-description`">
                        <validation-provider
                          #default="{ errors }"
                          :name="`${lang.languageName} Description`"
                          rules="required"
                        >
                          <template>
                            <quill-editor
                              v-model="updateMenuContent.contents[index].description"
                              id="description"
                              :class="`mc-${lang.languageName}-description`"
                              :preserve-whitespace="false"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="`${lang.languageName} Description`"
                            />
                          </template>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  <!-- </b-col> -->
                <!-- </span> -->
              </b-row>
            </b-form>
          </validation-observer>
          </b-overlay>
        </b-modal>
        <!-- Add / Update Menu Content Modal ***END*** -->


        <!-- Add Menu Modal ***START*** -->
        <b-modal
          id="modal-add-menu"
          ref="addMenuModal"
          title="Add New Menu"
          cancel-title="Close"
          ok-title="Save"
          centered
          size="sm"
          cancel-variant="outline-secondary"
          no-close-on-backdrop
          @ok="addNewMenu"
        >
          <validation-observer ref="addMenuFormRules">
            <b-form @submit.prevent>
              <b-row col>
                <b-col md="12">
                  <b-col md="12">
                    <b-form-group label="Name" label-for="mc-Name">
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="addMenuForm.name"
                          class="Name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Menu Type" label-for="mc-select-type">
                      <validation-provider
                        #default="{ errors }"
                        name="Menu Type"
                        rules="required"
                      >
                        <v-select
                          id="mc-select-type"
                          v-model="addMenuForm.type"
                          :options="menu_types"
                          class="type"
                          placeholder="Select Menu Type"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :reduce="val=> val.value"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-if="addMenuForm.type == 2">
                    <b-form-group label="Menu Category" label-for="mc-select-type">
                      <validation-provider
                        #default="{ errors }"
                        name="Menu Category"
                        rules="required"
                      >
                        <v-select
                          id="mc-select-type"
                          v-model="addMenuForm.sub_type"
                          :options="footer_cat"
                          class="type"
                          placeholder="Select Menu Category"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :reduce="val=> val.value"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="URL" label-for="mc-url">
                      <validation-provider
                        #default="{ errors }"
                        name="URL"
                        rules="required|url"
                      >
                        <b-form-input
                          v-model="addMenuForm.url"
                          :class="addMenuForm.name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter external URL"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-show="false">
                    <b-form-group label="Is Active" label-for="mc-is-active">
                      <b-form-checkbox
                        v-model="addMenuForm.is_active"
                        class="custom-control-primary checkStyle"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="Link2Icon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="DiscIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
        <!-- Add Menu Modal ***END*** -->

        <!-- submit and reset -->
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="float-right"
              @click="$emit('on-submit', contentDetails, 'content')"
              :disabled="isDisabledSave"
            >
              Finish
              <feather-icon
                icon="SaveIcon"
                size="14"
              />
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BAlert,
  BButton,
  BFormCheckbox,
  BFormRadio,
  VBModal,
  BOverlay,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, url } from "@core/utils/validations/validations";
import Ripple from "vue-ripple-directive";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import WhitelabelService from '@/services/whitelabel/whitelabel.service';
import languageService from "@/services/language/language.service";
import { resConditionCheck } from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ConfirmDisable from "../../confirm-disable/ConfirmDisable.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BCardCode,
    BAlert,
    BButton,
    BFormCheckbox,
    BFormRadio,
    quillEditor,
    BOverlay,
    BInputGroupAppend,
    BInputGroup,
    ConfirmDisable,
  },

  directives: {
    "b-modal": VBModal,
    Ripple
  },

  props: {
    contentDetails: {
      type: Object,
      required: true
    },
    whitelabelId: {
      type: Number,
      required: true
    },
    // languages: {
    //   type: Array,
    //   required: true
    // },
    footerMenus: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      required,
      url,
      icons: {
        Facebook: 'FacebookIcon',
        Instagram: 'InstagramIcon',
        Youtube: 'YoutubeIcon',
        Linkedin: 'LinkedinIcon'
      },
      menu_types: [
        { label: 'Header', value: 1 },
        { label: 'Footer', value: 2 }
      ],
      // Menu data will be defined into parent component - whitelabel-details
      footer_cat: [
        { label: 'Take a look around', value: 1 },
        { label: 'About us', value: 2 },
        { label: 'Legal information', value: 3 }
      ],
      // footer_menus: [ [], [], [] ],
      addMenuForm: {
        name: '',
        type: '',
        sub_type: '',
        is_url: true,
        url: '',
        is_active: false
      },
      updateMenuContent: {
        id: '',
        type: '',
        sub_type: '',
        is_url: false,
        is_slug: true,
        url: '',
        is_active: true,
        whitelabel_id: this.whitelabelId,
        contents: []
      },
      contentLangStaticObj: {
				id: '',
				name: '',
				description: '',
				language_id: ''
			},
      menuName: '',
      isLoading: false,
      languages: [],
      isDisabledSave: false,
      disableMsg: "",
      wlId: "",
      status2: ""
    };
  },

  watch: {
    contentDetails: {
      deep: true,
      handler() {
        this.getDisbeledStatus();
      },
      deep: true
    },
    footerMenus: {
      handler() {
        this.getDisbeledStatus()
      },
      deep: true
    }
  },

  mounted() {
    this.getDisbeledStatus()
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(bvEvent.trigger == 'headerclose' || bvEvent.trigger == 'cancel') {  
        if (modalId == 'modal-add-edit-content') {
          this.hideContentmodal()
        } else if(modalId == 'modal-add-menu') {
          this.hideAddMenuModal()
        }
      }
    })
    /**this.contentDetails.footer_links.forEach(item => {
      if(item.subType === 1) {
        this.footer_menus[0].push(item) 
      } else if(item.subType === 2) {
        this.footer_menus[1].push(item)  
      } else if(item.subType === 3) {
        this.footer_menus[2].push(item)  
      }
    }) */
  },

  methods: {
    updateContentType(val, index, type, idx=null) {
      if(type === 'header') {
        this.contentDetails.header_links[index].isUrl = val === 'url' 
          ? true
          : val === 'slug'
            ? false 
            : this.contentDetails.header_links[index].isUrl

        this.contentDetails.header_links[index].isSlug = val === 'slug'
          ? true
          : val === 'url'
            ? false
            : this.contentDetails.header_links[index].isSlug

      } else if(type === 'footer' && idx !== null) {
        this.footerMenus[idx][index].isUrl = (val === 'url') 
          ? true
          : (val === 'slug')
            ? false 
            : this.footerMenus[idx][index].isUrl

        this.footerMenus[idx][index].isSlug = (val === 'slug')
        ? true
        : (val === 'url')
          ? false
          : this.footerMenus[idx][index].isSlug

      }
    },

    async validationForm() {
      let valid = null;
      await this.$refs.contentRules.validate().then(success => {
        if (success) {
          valid = true;
        } else {
          valid = false;
          this.scrollToTop();
        }
      });
      return valid;
    },

    async activeDeactiveMenu(menuId, type, footerCat) {
      if (menuId) {
        await WhitelabelService.activeDeactiveMenu(menuId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.getDisbeledStatus();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            const errorData = errorResponseHandler(error);
            this.resetMenuStatus(menuId, type, footerCat);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },

    resetMenuStatus(menuId, type, footerCat) {
      if(type == 1) {
        const foundIndex = this.contentDetails.header_links.findIndex(item => item.id === menuId);
        this.$set(this.contentDetails.header_links[foundIndex], "isActive", false);
      } else if(type == 2) {
        const foundIndex = this.footerMenus[footerCat].findIndex(item => item.id === menuId);
        this.$set(this.footerMenus[footerCat][foundIndex], "isActive", false);
      }
    },

    async addNewMenu(bvModalEvt) {
      bvModalEvt.preventDefault();
      await this.$refs.addMenuFormRules.validate().then(success => {
        if (success) {
          this.addMenuForm.whitelabel_id = this.whitelabelId
          const type = this.addMenuForm.type;
          const sub_type = this.addMenuForm.sub_type;
          WhitelabelService.addNewMenu(this.addMenuForm).then(res => {
            if (resConditionCheck(res.status) && res.data) {
              this.$nextTick(() => {
                this.$emit("on-submit");
                this.$bvModal.hide("modal-add-menu");
                this.hideAddMenuModal();
              });
              if(type == 1) {
                this.contentDetails.header_links.push(res.data.result)
              } else if(type == 2){
                if(sub_type == 1) {
                  this.footerMenus[0].push(res.data.result)
                } else if(sub_type == 2) {
                  this.footerMenus[1].push(res.data.result)
                } else if(sub_type == 3) {
                  this.footerMenus[2].push(res.data.result)
                }
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "X-CircleIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            console.log(error)
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
        }
      });
    },

    async updateMenuUrl(menuData,formRef) {
      await this.$refs[formRef][0].validate().then(success => {
        if (success) {
          if(menuData.isUrl) {
            menuData.isSlug = false;
            menuData.slug = '';
          } else {
            menuData.isSlug = true;
          }

          let req_data = {
              is_active: menuData.isActive,
              type: menuData.type,
              sub_type: (menuData.type == 2) ? menuData.subType : 0,
              is_url: menuData.isUrl,
              url: (menuData.isUrl) ? menuData.url : "",
              is_slug: menuData.isSlug,
              whitelabel_id: menuData.wlCode,
          }
          this.updateMenuDetailService(menuData, req_data, 1);
        }
      });
    },

    async updateMenu(bvModalEvt){
      bvModalEvt.preventDefault()
      await this.$refs.addUpdateMenuFormRules.validate().then(success => {
        if (success) {
          this.updateMenuDetailService(this.updateMenuContent, this.updateMenuContent, 2);
        }
      });
    },

    async updateMenuDetailService(menuData, req_data, isUrl) {
      await WhitelabelService.updateMenu(menuData.id, req_data).then(res => {
        if (resConditionCheck(res.status) && res.data) {
          // this.loading = false;
          if(isUrl == 2) {
            if(req_data.type == 1) {
              this.contentDetails.header_links.forEach(m=> {
                if(m.id == menuData.id) {
                  m.contentLanguages = req_data.contents[0]
                }
              })
            } else if(req_data.type == 2) {
              if(req_data.sub_type == 1) {
                this.footerMenus[0].forEach(m=> {
                  if(m.id == menuData.id) {
                    m.contentLanguages = req_data.contents[0]
                  }
                })
              } else if(req_data.sub_type == 2) {
                this.footerMenus[1].forEach(m=> {
                  if(m.id == menuData.id) {
                    m.contentLanguages = req_data.contents[0]
                  }
                })
              } else if(req_data.sub_type == 3) {
                this.footerMenus[2].forEach(m=> {
                  if(m.id == menuData.id) {
                    m.contentLanguages = req_data.contents[0]
                  }
                })
              }
            }
            this.$nextTick(() => {
              this.$emit("on-submit");
              this.$bvModal.hide("modal-add-edit-content");
              this.hideContentmodal();
            });
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "X-CircleIcon",
              variant: "success"
            }
          });
        }
      })
      .catch(error => {
        // this.loading = false;
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      });
    },

    async openUpdateContentModal(menuId) {
      this.isLoading = true;
      await this.getLanguageDropdown();
      await WhitelabelService.getMenuDetail(menuId).then(res => {
        if (resConditionCheck(res.status) && res.data) {
          this.isLoading = false;
          this.menuName = res.data.data.name;
          this.updateMenuContent.id = res.data.data.id;
          this.updateMenuContent.type = res.data.data.type;
          this.updateMenuContent.sub_type =  res.data.data.subType;
          this.updateMenuContent.whitelabel_id =  res.data.data.wlCode;

          // if(res.data.data.contentLanguages.length) {for ( let i = 0; i < res.data.data.contentLanguages.length; i += 1 ) { const name = this.languages.filter(ln => { return res.data.data.contentLanguages[i].languageId === ln.id })[0].name; this.updateMenuContent.contents.push({ ...this.contentLangStaticObj, language_id: res.data.data.contentLanguages[i].languageId, id: res.data.data.contentLanguages[i].id, languageName: name }); this.updateMenuContent.contents[i].name = res.data.data.contentLanguages[i].name; this.updateMenuContent.contents[i].description = res.data.data.contentLanguages[i].description; } } else { for (let i = 0; i < this.languages.length; i += 1) { if(this.languages[i].isActive) { this.updateMenuContent.contents.push({ ...this.contentLangStaticObj, language_id: this.languages[i].id, languageName: this.languages[i].name }); } } }

          for ( let i = 0; i < this.languages.length; i++ ) {
            if(this.languages[i].isActive) {
              const content = res.data.data.contentLanguages.filter(ln => {
                  // console.log(ln)
                  return ln.languageId == this.languages[i].id
              });
              if(content.length) {
                const id = content[0].id
                const name = content[0].name
                const description = content[0].description
                this.updateMenuContent.contents.push({
                  ...this.contentLangStaticObj,
                  language_id: content[0].languageId,
                  id: id,
                  languageName: this.languages[i].name,
                  name,
                  description
                });
              } else {
                this.updateMenuContent.contents.push({
                  ...this.contentLangStaticObj,
                  language_id: this.languages[i].id,
                  languageName: this.languages[i].name
                });
              }
            }
          }
        }
      })
      .catch(error => {
        this.isLoading = false;
        console.log(error)
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      });
    },

    hideContentmodal() {
      setTimeout(()=>{
        this.updateMenuContent.id = '';
        this.updateMenuContent.type = '';
        this.updateMenuContent.sub_type = '';
        this.updateMenuContent.whitelabel_id = this.whitelabelId;
        this.updateMenuContent.contents = [];
      }, 500)
    },

    hideAddMenuModal() {
      setTimeout(()=>{ 
        this.addMenuForm.name =  '';
        this.addMenuForm.type =  '';
        this.addMenuForm.sub_type =  '';
        this.addMenuForm.url =  '';
      }, 500)
    },

    scrollToTop() {
      window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
    },

    async getLanguageDropdown() {
      try {
        const res = await languageService.getLanguageDropdown(this.whitelabelId);
        if (resConditionCheck(res.status) && res.data.data) {
          this.languages = res.data.data;
        }
      } catch (error) {
        this.languages = []
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    getDisbeledStatus() {
      let arr = this.contentDetails.header_links.concat(this.footerMenus[0],this.footerMenus[1], this.footerMenus[2]);
      const isFalse = arr.map((menu)=>{
        if(menu.isActive) {
          if(!menu.isSlug && !menu.isUrl) return true;
          else if(menu.isSlug && menu?.contentLanguages?.length < 1) return true;
          else return false;
        } else {
          return false;
        }
      });
      this.isDisabledSave = (isFalse.find(v=> v === true)) ? true : false;
    },

    openNewsletterModal(id, status) {
        this.wlId = id;
        this.status2 = !status;
        this.$refs.newsLetterModal.show();
        this.disableMsg = !status
          ? `Are you sure you want to disable Newsletter ?`
          : `Are you sure you want to enable Newsletter ?`;
      },
      resetShowNewsletter() {
        this.$set(this.contentDetails, "show_newsletter", this.status2);
      },

      showNewsletter(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.$refs.newsLetterModal.loading = true;
        if (this.wlId) {
          WhitelabelService.updateShowNewsletter(this.wlId)
            .then((res) => {
              this.$refs.newsLetterModal.loading = false;
              this.$refs.newsLetterModal.toggleModal = false;
              if (resConditionCheck(res.status)) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
              }
            })
            .catch((error) => {
              this.$refs.newsLetterModal.loading = false;
              this.$refs.newsLetterModal.toggleModal = false;
              const errorData = errorResponseHandler(error);
              this.resetShowNewsletter();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      },
  }
};
</script>

<style>
.ql-editor {
  min-height: 150px !important;
  max-height: 350px !important;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>

<style scoped>
  .checkStyle {
    margin-top: 0.5rem;
  }
  .headingStyle {
    /* background: #eee; */
    padding: 12px;
    /* color: var(--color-primary); */
    font-weight: 600;
  }
  .inline-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .inline-box > *{ 
    margin: 0rem 0.5rem;
  }
</style>