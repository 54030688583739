<template>
  <div>
    <confirm-disable
      ref="disableModuleModal"
      :msg="moduledisableMsg"
      @on-submit="switchModuleStatus"
      @on-hide="resetModuleStatus"
    />
    <div class="mb-2">
      <h3 class="mb-2">Languages</h3>
      <b-row>
        <b-col lg="4" md="12"
          v-for="(language, index) in settingDetails.languages"
          :key="index"
        >
          <b-form-group
            v-if="!language.isDeleted"
            :label="language.name"
            label-for="mc-lang_name"
            label-cols-lg="4"
          >
            <b-form-radio
              name="language"
              class="my-0 radioCheck"
              :value="language.name"
              :checked="language.isActive ? language.name : ''"
              @change="switchLanguageStatus(language.id)"
            >
            </b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr>
    <div class="mb-2">
      <h3 class="mb-2">Currencies</h3>
      <b-row>
      <b-col lg="4" md="12" v-for="(currency, index) in settingDetails.currencies"
        :key="index"
      >
        <b-form-group
          v-if="!currency.isDeleted"
          :label="`${currency.code} (${currency.symbol})`"
          label-for="mc-currency"
          label-cols-lg="4"
        >
          <b-form-radio
              name="currency"
              class="my-0 radioCheck"
              :value="currency.code"
              :checked="currency.isActive ? currency.code : ''"
              @change="switchCurrencyStatus(currency.id)"
            >
          </b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
    </div>
    <hr>
    <div class="mb-2">
      <h3 class="mb-2">Modules</h3>
      <b-row>
      <b-col lg="4" md="12" v-for="(module, index) in settingDetails.modules"
        :key="index"
      >
        <b-form-group :label="module.name" label-for="mc-module" label-cols-lg="4">
          <b-form-checkbox
            v-model="module.isActive"
            class="custom-control-primary checkStyle"
            switch
            @change="openModuleDisableModal( module.id, module.isActive, module.name )"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    </div>

    <!-- submit and reset -->
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="float-right"
          :disabled="isSubmitted"
            @click="$emit('next-step')"
        >
          <feather-icon
            icon="SaveIcon"
            size="14"
          />
          Continue
          <feather-icon
            icon="ChevronRightIcon"
            size="14"
          />
          <div v-if="loading" class="spinner-border spinner-border-sm" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BAlert,
  BButton,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { resConditionCheck } from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LanguageService from "@/services/language/language.service";
import CurrencyService from "@/services/currency/currency.service";
import WhitelabelService from "@/services/whitelabel/whitelabel.service";
import ConfirmDisable from "../../confirm-disable/ConfirmDisable.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BCardCode,
    BAlert,
    BButton,
    BFormCheckbox,
    ConfirmDisable,
    BFormRadio,
  },

  directives: {
    Ripple
  },

  props: {
    settingDetails: {
      type: Object,
      required: true
    },
    whitelabelId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      isSubmitted: false,
      languageId: "",
      currencyId: "",
      moduleId: "",
      langdisableMsg: "",
      currencydisableMsg: "",
      moduledisableMsg: "",
      langstatus2: "",
      currstatus2: "",
      modstatus2: ""
    };
  },

  watch: {
    'settingDetails.languages': {
      handler() {

      }
    }, 
    'settingDetails.currencies': {
      handler() {

      }
    }
    
  },

  async mounted() {
    
  },

  methods: {
    // Langauge active inactive
    switchLanguageStatus(id) {
      this.languageId = id;
      if (this.languageId) {
        LanguageService.switchLanguageStatus(this.languageId, this.whitelabelId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },

    // Currency active inactive
    switchCurrencyStatus(id) {
      this.currencyId = id;
      if (this.currencyId) {
        CurrencyService.switchCurrencyStatus(this.currencyId, this.whitelabelId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },

    //Module active inactive
    openModuleDisableModal(id, status, name) {
      this.moduleId = id;
      this.modstatus2 = !status;
      this.$refs.disableModuleModal.show();
      this.moduledisableMsg = !status
        ? `Are you sure you want to deactivate ${name} module ?`
        : `Are you sure you want to activate ${name} module ?`;
    },

    resetModuleStatus() {
      const foundIndex = this.settingDetails.modules.findIndex(
        item => item.id === this.moduleId
      );
      this.$set(this.settingDetails.modules[foundIndex], "isActive", this.modstatus2);
    },

    switchModuleStatus(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.disableModuleModal.loading = true;
      if (this.moduleId) {
        WhitelabelService.switchModuleStatus(this.moduleId, this.whitelabelId)
          .then(res => {
            this.$refs.disableModuleModal.loading = false;
            this.$refs.disableModuleModal.toggleModal = false;
            if (resConditionCheck(res.status)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.disableModuleModal.loading = false;
            this.$refs.disableModuleModal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            const foundIndex = this.settingDetails.modules.findIndex(
              item => item.id === this.moduleId
            );
            this.$set(this.settingDetails.modules[foundIndex], "isActive", this.modstatus2);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },
  }
};
</script>

<style scoped>
  .checkStyle {
    margin-top: 2%
  }
  .radioCheck {
    margin-top: 0.5rem !important;
  }
</style>
