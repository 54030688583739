<template>
  <div id="app">
    <!-- <h3></h3> -->
    <WhiteLabelDetails v-if="!id && isLoaded" :key="$route.fullPath" :is-loaded="isLoaded" @on-submit="createWhiteLabel"/>

    <!-- <h3>Edit User form</h3> -->
    <WhiteLabelDetails v-if="id && isLoaded" :key="$route.fullPath" :is-loaded="isLoaded" />
  </div>
</template>

<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from '@/services/errorHandler'
import { resConditionCheck } from '@/helpers/constant'
// import CustomerAdminService from '../../../../services/customer-admin/customerAdmin.service'
import WhiteLabelDetails from "./WhiteLabelDetails.vue";

export default {
  name: "WhiteLabelAddEdit",
  components: { WhiteLabelDetails },
  data() {
    return {
      isLoaded: true,
      whiteLabelDetails: {},
      id: this.$route.params.id ? this.$route.params.id : '',
    };
  },
  watch: {
    async $route() {
      if (this.$route.name === 'whitelabel-add') {
        // this.id = ''
        // this.whiteLabelDetails = {}
        this.isLoaded = true
      } else if (this.$route.name === 'whitelabel-edit') {
        // this.id = this.$route.params.id
        // await this.getUserById(this.$route.params.id);
        // this.isLoaded = true
      }
    }
  },
  // When Page Reload & Refresh
  async mounted() {
    // if (this.$route.params.id) {
    //   await this.getUserById(this.$route.params.id);
    // }
  },
  methods: {
    async createWhiteLabel(tabFormData, tabType) {
      console.log('tabFormData---',tabFormData)
      console.log('tabType---',tabType)
    }
    // Create Customer User
    // async createUser(userForm) {
    //   const valid = await this.$refs.simpleRules.validationForm()
    //   if (valid) {
    //     this.$refs.simpleRules.loading = true
    //     const { is_active, ...userData } = userForm

    //     CustomerAdminService.createCustomer(userData).then(res => {
    //       this.$refs.simpleRules.loading = false

    //       if (resConditionCheck(res.status) && res.data.data) {
    //         this.$router.push('/customers/customer-users/list')
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: res.data.message,
    //             icon: "CheckIcon",
    //             variant: "success",
    //           },
    //         });
    //       }
    //     }).catch(error => {
    //       setTimeout(() => {
    //         this.$refs.simpleRules.loading = false
    //       }, 3000)
    //       const errorData = errorResponseHandler(error)
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: errorData,
    //           icon: "X-CircleIcon",
    //           variant: "danger",
    //         },
    //       });
    //     })
    //   }
    //   // call an API to create a new user
    // },

    // // Update Admin User
    // async updateUser(userForm) {
    //   const valid = await this.$refs.simpleRules.validationForm()
    //   if (valid) {
    //     this.$refs.simpleRules.loading = true
    //     const
    //       {
    //         password, confirm_password, send_email, ...editData
    //       } = userForm

    //     CustomerAdminService.updateCustomer(this.id, editData).then(res => {
    //       this.$refs.simpleRules.loading = false
    //       if (resConditionCheck(res.status) && res.data.user) {
    //         this.$router.push('/customers/customer-users/list')
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: res.data.message,
    //             icon: "CheckIcon",
    //             variant: "success",
    //           },
    //         });
    //       }
    //     }).catch(error => {
    //       this.$refs.simpleRules.loading = false
    //       const errorData = errorResponseHandler(error)
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: errorData,
    //           icon: "X-CircleIcon",
    //           variant: "danger",
    //         },
    //       });
    //     })
    //   }
    //   // call an API to update the existing user
    // },
    // // When Route Refersh Check Which Route & Based On View Set with Data
    // async getUserById(id) {
    //   await CustomerAdminService.getCustomerById(id).then(res => {
    //     this.isLoaded = true;
    //     if (resConditionCheck(res.status) && res.data.data) {
    //       this.userDetails = res.data.data
    //     }
    //   }).catch(error => {
    //     const errorData = errorResponseHandler(error)
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: errorData,
    //         icon: "X-CircleIcon",
    //         variant: "danger",
    //       },
    //     });
    //   })
    // },
  },
};
</script>
