<template>
  <div>
    <b-overlay
      variant="white"
      :show="!isLoaded"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
    <validation-observer ref="clubRules">
      <b-form @submit.prevent>
        <b-row col>
          <b-col md="6">
            <b-form-group label="Club Name" label-for="mc-club_name">
              <validation-provider
                #default="{ errors }"
                name="Club Name"
                rules="required|min:3"
              >
                <b-form-input
                  v-model="clubDetailsForm.club_name"
                  id="mc-club_name"
                  class="club_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Club Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

           <b-col md="6">
            <b-form-group label="Front end Domain" label-for="mc-front_domain">
              <validation-provider
                #default="{ errors }"
                name="Front end Domain"
                rules="required|max:50|min:3|domain"
              >
                <b-form-input
                  v-model="clubDetailsForm.frontend_domain"
                  id="mc-front_domain"
                  class="front_domain"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Ex: abc.beontrips.hu"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Product Code" label-for="mc-product-code">
              <validation-provider
                #default="{ errors }"
                name="Product Code"
                rules="required|alphaNumeric"
              >
                <b-form-input
                  v-model="clubDetailsForm.product_code"
                  id="mc-product-code"
                  class="Product Code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Ex: UMHU"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Booking Code" label-for="mc-booking-code">
              <validation-provider
                #default="{ errors }"
                name="Booking Code"
                rules="required|alpha|max:3|min:3"
              >
                <b-form-input
                  v-model="clubDetailsForm.booking_code"
                  id="mc-booking-code"
                  class="hotel"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Ex: BON"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- submit and reset -->
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="float-right"
              :disabled="isSubmitted"
              @click="$emit('on-submit', clubDetailsForm, 'club')"
            >
              <feather-icon
                icon="SaveIcon"
                size="14"
              />
              Continue
              <feather-icon
                icon="ChevronRightIcon"
                size="14"
              />
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button
              variant="secondary"
              class="mr-1 float-right"
              :to="{name: 'whitelabel-list' }"
            >
            <feather-icon
                icon="ChevronLeftIcon"
                size="14"
              />
            Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BAlert,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, alphaNumeric, alpha } from "@core/utils/validations/validations";
import DatePicker from "vue2-datepicker";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BCardCode,
    DatePicker,
    BAlert,
    BButton,
    BOverlay,
  },

  directives: {
    Ripple
  },

  props: {
    clubDetailsForm: {
      type: Object,
      required: true
    },
    isLoaded: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      isSubmitted: false,
      required,
      alphaNumeric,
      alpha
    };
  },

  watch: {
    clubDetailsForm: {
      deep: true,
      handler() {
        this.clubDetailsForm.booking_code = this.clubDetailsForm.booking_code.toUpperCase()
      }
    }
  },

  async mounted() {
    
  },

  methods: {
    async validationForm() {
      let valid = null;
      await this.$refs.clubRules.validate().then(success => {
        if (success) {
          valid = true;
        } else {
          valid = false;
        }
      });
      return valid;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>