<template>
  <div>
    <!-- <button class="btn btn-primary" @click="formWizardNextStep">
      NEXT
    </button> -->
    <form-wizard
      ref="refFormWizard"
      color="primary"
      :title="null"
      :subtitle="null"
      hide-buttons
      variant="primary"
      class="wizard-vertical"
    >

      <!-- Club Details -->
      <tab-content
        title="Club Details"
        icon="feather icon-layers"
      >
        <club-add-details
          ref="clubRules"
          :is-loaded="isLoaded"
          :club-details-form="clubDetailsForm"
          @on-submit="addWhitelabel"
        />
      </tab-content>

      <!-- User Details -->
      <tab-content
        title="User Details"
        icon="feather icon-user"
      >
        <user-add-details
          ref="userRules"
          :is-loaded="isLoaded"
          :user-details-form="userDetailsForm"
          @on-submit="addWhitelabel"
        />

      </tab-content>

      <!-- Branding -->
      <tab-content
        title="Branding"
        icon="feather icon-git-branch"
      >
        <branding-add-details
          v-if="isLoaded"
          ref="brandwizard"
          :branding-smtp-form="brandingSmtpForm"
          :branding-theme-form="brandingThemeForm"
          :is-loaded="isLoaded"
          @on-submit="addWhitelabel"
        />

      </tab-content>

       <!-- Setting -->
      <tab-content
        title="Setting"
        icon="feather icon-settings"
      >
       <setting-details
          v-if="isLoaded"
          :is-loaded="isLoaded"
          :whitelabel-id="whitelabelID"
          :setting-details="getsettingDetails"
          @next-step="formWizardNextStep"
        />
      </tab-content>

      <!-- Content -->
      <tab-content
        title="Content"
        icon="feather icon-external-link"
      >
        <content-details
          v-if="isLoaded"
          ref="contentRules"
          :is-loaded="isLoaded"
          :whitelabel-id="whitelabelID"
          :content-details="contentLinksData"
          :languages="settingDetails.languages ? settingDetails.languages : []"
          :footer-menus="footerMenus"
          @on-submit="addWhitelabel"
        />

      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import { resConditionCheck } from '@/helpers'
import errorResponseHandler from '@/services/errorHandler'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ClubAddDetails from './ClubAddDetails.vue'
import UserAddDetails from './UserAddDetails.vue'
import BrandingAddDetails from './BrandingAddDetails.vue'
import SettingDetails from './SettingDetails.vue'
import ContentDetails from './ContentDetails.vue'
import whitelabelService from '@/services/whitelabel/whitelabel.service'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BCard,
    BCardText,
    ClubAddDetails,
    UserAddDetails,
    BrandingAddDetails,
    SettingDetails,
    ContentDetails,
  },

  data() {
    return {
      refFormWizard: '',
      isLoaded: false,
      mainRes: [],
      clubDetailsForm: {},
      userDetailsForm: {},
      brandingSmtpForm: {},
      brandingThemeForm: {
        primary_color: '#3f8d85',
        secondary_color: '#3f8d85',
        button_color: '#3f8d85',
        logo:'',
        fav:'',
        footer_title: '',
        footer_description: '',
      },
      socialLinksForm: {},
      settingDetails: {},
      contentLinksData: {
        social_links: [
          {
            name: "Facebook",
            url: "",
            is_active: false
          },
          {
            name: "Instagram",
            url: "",
            is_active: false
          },
          {
            name: "Youtube",
            url: "",
            is_active: false
          },
          {
            name: "Linkedin",
            url: "",
            is_active: false
          }
        ],
        header_links: [],
        footer_links: [],
        show_newsletter: false
      },
      whitelabelID: this.$route.params.id ? Number(this.$route.params.id) : 0,
      whitelabelEdit: this.$route.name !== 'whitelabel-add',
      footerMenus: [ [], [], [] ],
    }
  },

  computed: {
    getsettingDetails() {
      if (this.settingDetails !== '') {
        return this.settingDetails
      }
      return {}
    },
  },

  async created() {
    
  },

  async mounted() {
    if(this.whitelabelEdit) {
      await this.getWhitelabelById(this.$route.params.id);
    } else {
      this.isLoaded = true;
    }
  },

  methods: {
    async addWhitelabel(tabFormData, tabType) {
      let valid; let formData = new FormData();
      formData.append('tab_type', tabType);
      formData.append('whitelabel_id', this.whitelabelID ? this.whitelabelID : '');

      if(tabType === 'club') {
        valid = await this.$refs.clubRules.validationForm()
        this.$refs.clubRules.loading =  valid ? true : false

        formData.append('name', tabFormData.club_name);
        formData.append('frontend_dom', tabFormData.frontend_domain);
        formData.append('product_code', tabFormData.product_code);
        formData.append('booking_code', tabFormData.booking_code);

      } else if (tabType === 'user') {
        valid = await this.$refs.userRules.validationForm()
        this.$refs.userRules.loading =  valid ? true : false

        formData.append('first_name', tabFormData.first_name);
        formData.append('last_name', tabFormData.last_name);
        formData.append('email', tabFormData.email);
        formData.append('password', tabFormData.password);
        formData.append('confirm_password', tabFormData.confirm_password);
        formData.append('country_id', tabFormData.country_id);
        formData.append('state', tabFormData.state);
        formData.append('city_name', tabFormData.city_name);
        formData.append('address', tabFormData.address);

      } else if (tabType === 'branding_smtp') {
        valid = await this.$refs.brandwizard.validationForm(1)
        this.$refs.brandwizard.loading =  valid ? true : false

        formData.append('smtp_host', tabFormData.smtp_host);
        formData.append('smtp_user', tabFormData.smtp_user);
        formData.append('smtp_password', tabFormData.smtp_password);
        formData.append('smtp_port', tabFormData.smtp_port);
        formData.append('smtp_email', tabFormData.smtp_email);

      } else if (tabType === 'branding_theme') {
        valid = await this.$refs.brandwizard.validationForm(2)
        this.$refs.brandwizard.loading =  valid ? true : false

        formData.append('primary_color', tabFormData.primary_color);
        formData.append('secondary_color', tabFormData.secondary_color);
        formData.append('button_color', tabFormData.button_color);
        tabFormData.logo && tabFormData.logo.name ? formData.append('logo', tabFormData.logo, tabFormData.logo.name): '';
        tabFormData.fav && tabFormData.fav.name ? formData.append('fav', tabFormData.fav, tabFormData.fav.name) : '';
        formData.append('footer_title', tabFormData.footer_title);
        formData.append('footer_description', tabFormData.footer_description);

      } else if (tabType === 'content') {
        valid = await this.$refs.contentRules.validationForm()
        this.$refs.contentRules.loading =  valid ? true : false
        formData.append('social_links', JSON.stringify(tabFormData.social_links))
      }
      if(valid) {
        // if(tabType === 'club' && this.whitelabelID === '') {
          whitelabelService.addWhitelabel(formData)
          .then(res => {
            if (resConditionCheck(res.status) && res.data.data) {
              let msg = res.data.message;
              if(tabType === 'club') {
                this.$refs.clubRules.loading = false;
                this.whitelabelID = res.data.data.id;
              }
              else if (tabType === 'user') {
                this.$refs.userRules.loading = false;
                this.settingDetails = res.data.settings;
              }
              else if (tabType === 'branding_smtp') {
                this.$refs.brandwizard.loading = false;
                this.contentLinksData.header_links = res.data.contentData && res.data.contentData.header ? res.data.contentData.header : this.contentLinksData.header;
                this.contentLinksData.footer_links = res.data.contentData && res.data.contentData.footer ? res.data.contentData.footer : this.contentLinksData.footer;
                if(!this.whitelabelEdit) {
                  this.getFooterMenuByCatgs();
                } 
              }
              else if (tabType === 'branding_theme') {
                this.$refs.brandwizard.loading = false
              }
              else if (tabType === 'content') {
                if(this.whitelabelEdit) {
                  msg = "Whitelabel details updated successfuly.";
                } else {
                  msg = "Whitelabel details added successfuly.";
                }
                this.$refs.contentRules.loading = false;
                this.$router.push({path: '/whitelabel/list'})
              }
              if(tabType !== 'branding_smtp') {
                this.formWizardNextStep()
              } else {
                this.$refs.brandwizard.nextTab()
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: msg,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          }).catch(error => {
            console.log('error---',error)
            if(tabType === 'club') { this.$refs.clubRules.loading = false }
            else if (tabType === 'user') { this.$refs.userRules.loading = false }
            else if (tabType === 'branding_smtp') { this.$refs.brandwizard.loading = false }
            else if (tabType === 'branding_theme') { this.$refs.brandwizard.loading = false }
            else if (tabType === 'content') { this.$refs.contentRules.loading = false }
            const errorData = errorResponseHandler(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          });
        // } else {
        //   this.formWizardNextStep()
        // }
      }
    },

    async getWhitelabelById(id) {
      try {
        const res = await whitelabelService.getWhitelabelDetail(id);
        this.isLoaded = true
        if (resConditionCheck(res.status) && res.data.data) {
          this.clubDetailsForm = res.data.data.clubData ? res.data.data.clubData : this.clubDetailsForm;
          this.userDetailsForm = res.data.data.userData ? res.data.data.userData : this.userDetailsForm;
          this.brandingSmtpForm = res.data.data.smtpData ? res.data.data.smtpData : this.brandingSmtpForm;
          this.brandingThemeForm = res.data.data.themeData ? res.data.data.themeData : this.brandingThemeForm;
          this.settingDetails = res.data.data.settingsData ? res.data.data.settingsData : this.settingDetails;
          this.contentLinksData.social_links = res.data.data.contentData && res.data.data.contentData.social_links ? res.data.data.contentData.social_links : this.contentLinksData.social_links;
          this.contentLinksData.header_links = res.data.data.contentData && res.data.data.contentData.header ? res.data.data.contentData.header : this.contentLinksData.header;
          this.contentLinksData.footer_links = res.data.data.contentData && res.data.data.contentData.footer ? res.data.data.contentData.footer : this.contentLinksData.footer;
          this.contentLinksData.show_newsletter = res.data.data.contentData && res.data.data.contentData.show_newsletter ? res.data.data.contentData.show_newsletter : this.contentLinksData.show_newsletter;
          this.getFooterMenuByCatgs();

          for(var i=0; i <= 4; i++) {
            if(i === 3 && !this.brandingSmtpForm.smtp_email) {
              this.$refs.refFormWizard.deactivateTabs(i);
              break;
            }else {
              this.$refs.refFormWizard.activateTabAndCheckStep(i);
            }
          }
            this.$refs.refFormWizard.activateTabAndCheckStep(0);
        }
      } catch (error) {
        console.log(error)
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },
    
    getFooterMenuByCatgs() {
      this.contentLinksData.footer_links.forEach(item => {
        if(item.subType === 1) {
          this.footerMenus[0].push(item) 
        } else if(item.subType === 2) {
          this.footerMenus[1].push(item)  
        } else if(item.subType === 3) {
          this.footerMenus[2].push(item)  
        }
      })
    },

  },

  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    return {
      refFormWizard,
      formWizardNextStep,
    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .wizard-icon-circle .wizard-icon-container .wizard-icon {
  color: #000 !important;
}
</style>
