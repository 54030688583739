<template>
  <div>
    <!-- <button class="btn" @click="nextTab">
      NEXT
    </button> -->

    <!-- {{brandingThemeForm.logo}}
    {{logoPreview}} -->
    <form-wizard
      ref="brandwizard"
      color="primary"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      hide-buttons
      class="wizard-vertical mb-1 subWizard"
      @on-complete="formSubmitted"
    >

      <!-- SMTP tab -->
      <tab-content
        title="SMTP Setting"
        icon="feather icon-server"
      >
        <validation-observer ref="smtpRules">
          <b-form @submit.prevent>
            <b-row col>
              <b-col md="6">
                <b-form-group label="Host" label-for="mc-host">
                  <validation-provider
                    #default="{ errors }"
                    name="Host"
                    rules="required"
                  >
                    <b-form-input
                      v-model="brandingSmtpForm.smtp_host"
                      id="mc-host"
                      class="host"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ex: smtp.domain.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Username" label-for="mc-username">
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    rules="required"
                  >
                    <b-form-input
                      v-model="brandingSmtpForm.smtp_user"
                      id="mc-username"
                      class="username"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Username"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Password"
                  label-for="mc-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">

                      <b-form-input
                        v-model="brandingSmtpForm.smtp_password"
                        class="password"
                        :type="passwordFieldTypeNew"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Your Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconNew"
                          class="cursor-pointer togglePasswordNew"
                          @click="togglePasswordNew"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Port" label-for="mc-port">
                  <validation-provider
                    #default="{ errors }"
                    name="Port"
                    rules="required"
                  >
                    <b-form-input
                      v-model="brandingSmtpForm.smtp_port"
                      id="mc-port"
                      class="port"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ex: 25"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Sender Email Address" label-for="mc-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender Email Address"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="brandingSmtpForm.smtp_email"
                      id="mc-email"
                      class="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Sender Email Address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- submit and reset -->
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="float-right"
                  :disabled="isSubmitted"
                  @click="$emit('on-submit', brandingSmtpForm, 'branding_smtp')"
                >
                  <!-- @click="$refs.brandwizard.nextTab()" -->
                  <feather-icon
                    icon="SaveIcon"
                    size="14"
                  />
                  Continue
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="14"
                  />
                  <div v-if="loading" class="spinner-border spinner-border-sm" />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
      <!-- Theme tab -->
      <tab-content
        title="Theme Setting"
        icon="feather icon-layout"
      >
        <validation-observer ref="themeRules">
          <b-form @submit.prevent>
            <b-row col>
              <b-col md="4">
                <b-form-group label="Theme Primary Color" label-for="mc-primary-color">
                  <validation-provider
                    #default="{ errors }"
                    name="Theme Primary Color"
                    rules="required"
                  >
                    <color-picker
                      :color="brandingThemeForm.primary_color"
                      v-model="brandingThemeForm.primary_color" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group label="Theme Secondary Color" label-for="mc-secondary-color">
                  <validation-provider
                    #default="{ errors }"
                    name="Theme Secondary Color"
                    rules="required"
                  >
                    <color-picker
                      :color="brandingThemeForm.secondary_color"
                      v-model="brandingThemeForm.secondary_color" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group label="Button Color" label-for="mc-button-color">
                  <validation-provider
                    #default="{ errors }"
                    name="Button Color"
                    rules="required"
                  >
                    <color-picker
                      :color="brandingThemeForm.button_color"
                      v-model="brandingThemeForm.button_color" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row md="12">
              <b-col md=12>
                <!-- <validation-provider
                  #default="{ errors }"
                  name="Footer Title"
                  rules="required"
                > -->
                  <label for="textarea-title">Footer Title</label>
                  <b-form-input
                    v-model="brandingThemeForm.footer_title"
                    id="textarea-title"
                    placeholder="Footer Title "
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-col>
              <b-col md="12">
                <!-- <validation-provider
                  #default="{ errors }"
                  name="Footer Description"
                  rules="required"
                > -->
                  <label for="textarea-desc">Footer Description</label>
                  <b-form-textarea
                    id="textarea-desc"
                    v-model="brandingThemeForm.footer_description"
                    placeholder="Footer Description"
                    rows="4"
                  />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-col>
            </b-row>
            <b-row col>
              <b-col md="6" class="mt-2">
                <h6>Club Logo</h6>
                <div class="helper" />
                <div class="drop align-center" @dragover.prevent @drop="onDrop">
                  <div class="helper" />
                  <label
                    v-if="!logoPreview && !brandingThemeForm.logo"
                    class="btn btn-primary"
                  >
                    SELECT OR DROP
                    <input
                      type="file"
                      accept="image/*"
                      name="logo"
                      @change="onLogoChange"
                    >
                  </label>
                  <div
                    v-if="logoPreview"
                    class=" display-inline align-center"
                    :class="{ image: true }"
                  >
                    <img :src="logoPreview" alt="Image" class="img mt-2">
                    <br>
                    <br>
                    <button class="btn btn-primary mb-2" @click="removeFile(1)">
                      REMOVE
                    </button>
                  </div>
                  <div
                    v-if="brandingThemeForm.logo && !logoPreview && $route.params.id"
                    class=" display-inline align-center"
                    :class="{ image: true }"
                  >
                    <img :src="brandingThemeForm.logo" alt="Image" class="img mt-2">
                    <br>
                    <br>
                    <button class="btn btn-primary mb-2" @click="removeFile(1)">
                      REMOVE
                    </button>
                  </div>
                </div>
                 <b-alert class="mt-2" variant="warning" show>
                  <div class="alert-body">
                    <span>Allowed JPG, JPEG, GIF or PNG. Max size of 1MB.</span>
                  </div>
                </b-alert>
              </b-col>
              <b-col md="6" class="mt-2">
                <h6>Favicon Logo</h6>
                <div class="helper" />
                <div class="drop align-center" @dragover.prevent @drop="onDrop">
                  <div class="helper" />
                  <label
                    v-if="!favPreview && !brandingThemeForm.fav"
                    class="btn btn-primary"
                  >
                    SELECT OR DROP
                    <input
                      type="file"
                      accept="image/*"
                      name="fav"
                      @change="onFavChange"
                    >
                  </label>
                  <div
                    v-if="favPreview"
                    class=" display-inline align-center"
                    :class="{ image: true }"
                  >
                    <img :src="favPreview" alt="Image" class="img mt-2">
                    <br>
                    <br>
                    <button class="btn btn-primary mb-2" @click="removeFile(2)">
                      REMOVE
                    </button>
                  </div>
                  <div
                    v-if="brandingThemeForm.fav && !favPreview && $route.params.id"
                    class=" display-inline align-center"
                    :class="{ image: true }"
                  >
                    <img :src="brandingThemeForm.fav" alt="Image" class="img mt-2">
                    <br>
                    <br>
                    <button class="btn btn-primary mb-2" @click="removeFile(2)">
                      REMOVE
                    </button>
                  </div>
                </div>
                <b-alert class="mt-2" variant="warning" show>
                  <div class="alert-body">
                    <span>Allowed ICO file Only. Max size of 1MB.</span>
                  </div>
                </b-alert>
              </b-col>
            </b-row>

        

            <!-- submit and reset -->
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="float-right"
                  :disabled="isSubmitted"
                  @click="$emit('on-submit', brandingThemeForm, 'branding_theme')"
                >
                  <feather-icon
                    icon="SaveIcon"
                    size="14"
                  />
                  Continue
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="14"
                  />
                  <div v-if="loading" class="spinner-border spinner-border-sm" />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        
      </tab-content>

    </form-wizard>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BAlert,
  BInputGroupAppend,
	BInputGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, email } from "@core/utils/validations/validations";
import Ripple from "vue-ripple-directive";
import ColorPicker from './ColorPicker.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
	  BInputGroup,
    BAlert,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationObserver,
    ValidationProvider,
    ColorPicker,
    BFormTextarea,
  },
  props: {
    brandingSmtpForm: {
      type: Object,
      required: true
    },
    brandingThemeForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      isSubmitted: false,
      logoPreview: "",
      favPreview: "",
      file: "",
      required,
      email,
      passwordFieldTypeNew: 'password',
    }
  },
  directives: {
    Ripple
  },
  created() {
    if(this.$route.name === 'whitelabel-add'){
      this.brandingThemeForm.logo = null
      this.brandingThemeForm.fav = null
    }
  },
  mounted() {
    if(this.brandingSmtpForm.smtp_email) {
      this.$refs.brandwizard.activateAll();
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.dataTransfer;
      this.createFile(files[0]);
    },

    onLogoChange(e) {
      const { files } = e.target;
      this.createLogoFile(files[0]);
    },

    onFavChange(e) {
      const { files } = e.target;
      this.createFavFile(files[0])
    },

    createLogoFile(file) {
      if ( (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png" || file.type === "image/gif") && file.size <= 1048576 ) {
        this.brandingThemeForm.logo = file;
        this.logoPreview = URL.createObjectURL(file, file.name);
      } else {
        this.brandingThemeForm.logo = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Only JPG, JPEG, GIF or PNG Allowed with Max Size Of 1 MB",
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    createFavFile(file) {
      console.log("-----------------FAV ICON file", file)
      console.log("-----------------FAV ICON file type--------", file.type)
      if ((file.type === "image/vnd.microsoft.icon" || file.type === "image/x-icon") && file.size <= 1048576) {
        this.brandingThemeForm.fav = file;
        this.favPreview = URL.createObjectURL(file, file.name);
      } else {
        this.brandingThemeForm.fav = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Only ICO Allowed with Max Size Of 1 MB",
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },
    
    removeFile(type) {
      if(type == 1) {
        if (this.brandingThemeForm.logo) {
          this.brandingThemeForm.logo = "";
        } else {
          this.logoPreview = "";
        }
      } else if(type == 2) {
        if (this.brandingThemeForm.fav) {
          this.brandingThemeForm.fav = "";
        } else {
          this.favPreview = "";
        }
      }
    },

    formSubmitted() {
      this.$emit('next-step')
    },

    async validationForm(type) {
      if(type == 1) {
        let valid = null;
        await this.$refs.smtpRules.validate().then(success => {
          if (success) {
            valid = true;
          } else {
            valid = false;
          }
        });
        return valid;
      } else if(type == 2) {
        let valid = null;
        await this.$refs.themeRules.validate().then(success => {
          if (success) {
            valid = true;
          } else {
            valid = false;
          }
        });
        return valid;
      }
    },

    nextTab() {
      this.$refs.brandwizard.nextTab()
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss">
.subWizard {
  background-color: #ededed4a !important;
}

.dropBtn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.dropBtn:hover {
  background-color: #722040;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

// .helper {
//   height: 100%;
//   display: inline-block;
//   vertical-align: middle;
//   width: 0;
// }

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.img {
  border: 1px solid #f6f6f6;
  display: inline-block;
  width: 300px;
  height: 200px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 0 10px;
}

.drop { 
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: transparent;
  border-radius: 2px;
  height: 300px;
  width: 600px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
h1 {
	height: 120px;
	line-height: 120px;
	text-align: center;
}
.vc-chrome {
	position: absolute;
	top: 35px;
	right: 0;
	z-index: 9;
}
.current-color {
	display: inline-block;
	width: 38px;
	height: 38px;
	background-color: #000;
	cursor: pointer;
}
</style>
